.App {
  text-align: center;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.text_logo {
  position: absolute;
  top: -90px;
  left: 20px;
  font-weight: bold;
}

.App-logo {
  height: 65vmin;
}

.App-header {
  background: linear-gradient(90.7deg,#00317b,#720066);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btn__wrapper {
  display: block;
  background-color: #fff;
  color: rgba(103,9,185,1);
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 15px;
  text-decoration: none;
}
